import { useState } from 'react';
import './App.css'
import Modal from './Components/Modal';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = (e) => {
    console.log(e.target.classList[0] === 'modal-overlay')
    if(e.target.classList[0] === 'modal-overlay'){
      setIsModalOpen(false)
    }
  }

  return (
    <div className='app' >
      <div className="default-page">
        <h1>User Details Modal</h1>
        <button onClick={() => setIsModalOpen(prev => !prev)}>Open Form</button>
      </div>
      {isModalOpen &&
        <div onClick={closeModal} className='modal-overlay'>
          <Modal />
        </div>
      }
    </div>
  );
}

export default App;
