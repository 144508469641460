import { useState } from "react"

export default function Modal() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        date: "",
    })

    const handleInput = (e) => {
        console.log(e.target.value)
        setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))
    }

    const isValidEmail = (email) => {
        if (!email.includes("@")) return false
        else {
            if (email.split("@")[0].length === 0) return false
            if (email.split("@")[1].length === 0) return false
        }
        return true
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!isValidEmail(formData.email)) {
            alert("Invalid email")
            return
        }
        if (formData.phone.length !== 10) {
            alert("Invalid phone number. Please enter a 10-digit phone number.")
            return
        }
        if (new Date(formData.date) > new Date()) {
            alert("Invalid date of birth. Date of birth cannot be in the future.")
            return
        }
        setFormData({
            name: "",
            email: "",
            phone: "",
            date: "",
        })
    }

    return (
        <div className="modal">
            <h2 >Fill Details</h2>

            <div className="modal-content">
                <form>
                    <label for="name">Username:</label><br />
                    <input id="username" name="name" type="text" value={formData.name} onChange={handleInput} required />

                    <label for="email">Email Address:</label> <br />
                    <input id="email" name="email" type="email" value={formData.email} onChange={handleInput} required />

                    <label for="phone">Phone Number:</label><br />
                    <input id="phone" name="phone" type="number" value={formData.phone} onChange={handleInput} required />

                    <label for="date">Date of Birth:</label><br />
                    <input id="dob" name="date" type="date" value={formData.date} onChange={handleInput} required />

                    <button className="submit-button" onClick={handleSubmit}>Submit</button>
                </form>
            </div>
        </div>
    )
}